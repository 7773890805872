import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import { FaCheck } from 'react-icons/fa';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="error" mdxType="Alert">
      <p>{`We have implemented recent changes to our GeoIP Legacy web services in line with
the retirement of GeoIP Legacy Databases. Please see our `}
        <a {...{
          "href": "https://blog.maxmind.com/2020/06/data-changes-to-geoip-legacy-and-minfraud-legacy-web-services-in-may-2022/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`blog
post`}</a>
        {`
for more information.`}</p>
    </Alert>
    <Alert type="warning" mdxType="Alert">
      <p><strong {...{
          "parentName": "p"
        }}>{`Note:`}</strong>
        {` This documentation is for the GeoIP legacy services. New customers do
not have access to these services. Please use the `}
        <a {...{
          "href": "/geoip/docs/web-services",
          "parentName": "p"
        }}>{`GeoIP2 web
services`}</a>
        {`.`}</p>
    </Alert>
    <p>{`The GeoIP web services allow you to look up information about a given IP address
using an HTTP-based API.`}</p>
    <div {...{
      "id": "toc-ip-geolocation-usage"
    }}><h2 {...{
        "id": "ip-geolocation-usage",
        "parentName": "div"
      }}>{`IP Geolocation Usage`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`IP geolocation is inherently imprecise. Locations are often near the center of
the population. Any location provided by a GeoIP database should not be used to
identify a particular address or household.`}</p></div>
    <div {...{
      "id": "toc-http-based-api"
    }}><h2 {...{
        "id": "http-based-api",
        "parentName": "div"
      }}>{`HTTP-based API`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The HTTP API requires you to pass a set of parameters as an HTTP GET or POST.
Results are returned in a simple text format documented below.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`We offer several different services, each providing a different amount of
information about the IP address.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`All of the services take the same parameters as inputs. The only difference
between them is the URI they use and the data they return. The two parameters
that each service takes are the IP address to look up and
`}
        <a {...{
          "href": "https://www.maxmind.com/en/my_license_key",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`your MaxMind license key`}</a>
        {`.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The parameters should be passed in a query string or as a form post
(application/x-www-form-urlencoded). The IP address parameter should be named
`}
        <strong {...{
          "parentName": "p"
        }}>{`i`}</strong>
        {` (lower case "I") and the license key should be named `}
        <strong {...{
          "parentName": "p"
        }}>{`l`}</strong>
        {` (lower case
"L").`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The IP address should be passed as a string like "44.55.66.77" or
"2001:db8::2:1".`}</p>
      <div {...{
        "id": "toc-per-service-uris",
        "parentName": "div"
      }}><h3 {...{
          "id": "per-service-uris",
          "parentName": "div"
        }}>{`Per-Service URIs`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The URIs for each service are as follows:`}</p>
        <table {...{
          "parentName": "div"
        }}>

          <thead {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "thead"
            }}>

              <th {...{
                "parentName": "tr"
              }}>{`Service`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`URI`}</th>

            </tr>

          </thead>


          <tbody {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`Country`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/geoip/v1.0/country`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`City`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/geoip/v1.0/city`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`City/ISP/Org`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/geoip/v1.0/city-isp-org`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`Insights (formerly Omni)`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/geoip/v1.0/insights`}</inlineCode></td>

            </tr>

          </tbody>

        </table>
        <p {...{
          "parentName": "div"
        }}>{`You might also be using the original GeoIP Legacy URIs:`}</p>
        <table {...{
          "parentName": "div"
        }}>

          <thead {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "thead"
            }}>

              <th {...{
                "parentName": "tr"
              }}>{`Service`}</th>


              <th {...{
                "parentName": "tr"
              }}>{`URI`}</th>

            </tr>

          </thead>


          <tbody {...{
            "parentName": "table"
          }}>

            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`Country`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/a`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`City`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/b`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`City/ISP/Org`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/f`}</inlineCode></td>

            </tr>


            <tr {...{
              "parentName": "tbody"
            }}>

              <td {...{
                "parentName": "tr"
              }}>{`Insights (formerly Omni)`}</td>


              <td {...{
                "parentName": "tr"
              }}><inlineCode {...{
                  "parentName": "td"
                }}>{`https://geoip.maxmind.com/e`}</inlineCode></td>

            </tr>

          </tbody>

        </table>
        <p {...{
          "parentName": "div"
        }}>{`The `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`geoip.maxmind.com`}</inlineCode>
          {` hostname automatically picks the data center
geographically closest to you.`}</p></div>
      <div {...{
        "id": "toc-security",
        "parentName": "div"
      }}><h3 {...{
          "id": "security",
          "parentName": "div"
        }}>{`Security`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`You must access this service via HTTPS. We require TLS 1.2 or greater for HTTPS
requests to our servers to keep your data secure.`}</p></div>
      <div {...{
        "id": "toc-output",
        "parentName": "div"
      }}><h3 {...{
          "id": "output",
          "parentName": "div"
        }}>{`Output`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`All services return data as a set of comma-separated fields. The ISP name,
Organization name, and AS number fields are quoted, since they may contain a
comma. The other fields are not escaped or quoted, but they will never contain a
comma.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`All strings are returned in the
`}
          <a {...{
            "href": "https://en.wikipedia.org/wiki/ISO/IEC_8859-1",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`ISO-8859-1 encoding`}</a>
          {`. This
encoding is also referred to as latin1.`}</p>
        <table>
          <thead>
            <tr>
              <th colSpan="3" />
              <th colSpan="4">
                <p>{`Included in …`}</p>
              </th>
            </tr>
            <tr>
              <th>
                <p>{`Name`}</p>
              </th>
              <th>
                <p>{`Type`}
                  {` `}
                  {`(length)`}</p>
              </th>
              <th>
                <p>{`Description`}</p>
              </th>
              <th>
                <p>{`Country?`}</p>
              </th>
              <th>
                <p>{`City?`}</p>
              </th>
              <th>
                <p>{`City/ISP/Org?`}</p>
              </th>
              <th>
                <p>{`Insights (formerly Omni)?`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>{`Accuracy radius`}</p>
              </td>
              <td>
                <p>{`integer`}</p>
              </td>
              <td>
                <p>{`The radius in kilometers around the specified location where the IP address is
likely to be.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`City name`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The city or town name as defined by `}
                  <a href="https://www.geonames.org/">{`GeoNames`}</a>
                  {` associated with the IP address.`}</p>
              </td>
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Region code`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>
                  <p>{`The `}
                    <a href="https://en.wikipedia.org/wiki/ISO_3166-2">{`ISO-3166-2`}</a>
                    {` code for
the state/region associated with the IP address.`}</p>
                </p>
                <p>
                  <p>{`We previously returned a `}
                    <a href="https://en.wikipedia.org/wiki/FIPS_10-4">{`FIPS 10-4`}</a>
                    {` code for all countries other than the United States and Canada. See our `}
                    <a href="https://blog.maxmind.com/2020/06/data-changes-to-geoip-legacy-and-minfraud-legacy-web-services-in-may-2022/">{`blog post detailing changes to our legacy web services`}</a>
                    {`.`}</p>
                </p>
              </td>
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Region name`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The region name as defined by `}
                  <a href="https://www.geonames.org/">{`GeoNames`}</a>
                  {` associated with the IP address.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Postal code`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The postal code associated with the IP address. These are available for some IP
addresses in Australia, Canada, France, Germany, Italy, Spain, Switzerland,
United Kingdom, and the US.  We return the first 3 characters for Canadian
postal codes.  We return the first 2-4 characters (outward code) for
postal codes in the United Kingdom.`}</p>
              </td>
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Metro code`}</p>
              </td>
              <td>
                <p>{`integer`}</p>
              </td>
              <td>
                <p>{`The metro code associated with the IP address. These are only
available for IP addresses in the US.`}</p>
              </td>
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Area code`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>
                  <strong>
                    <p>{`Deprecated. This field will be empty in the updated legacy web service. See our `}
                      <a href="https://blog.maxmind.com/2020/06/data-changes-to-geoip-legacy-and-minfraud-legacy-web-services-in-may-2022/">{`blog post detailing changes to our legacy web services`}</a>
                      {`.`}</p>
                  </strong>
                </p>
              </td>
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Country code`}</p>
              </td>
              <td>
                <p>{`string (2)`}</p>
              </td>
              <td>
                <p>
                  <p>{`A `}
                    <a href="https://en.wikipedia.org/wiki/ISO_3166-1">{`ISO
3166-1`}</a>
                    {` country code for the country associated with the IP address. In
addition to the standard codes, we may also return one of the following:`}</p>
                </p>
                <ul>
                  <li>
                    <p><strong>{`A1`}</strong>
                      {` – an anonymous proxy.`}</p>
                  </li>
                  <li>
                    <p><strong>{`A2`}</strong>
                      {` – a satellite provider.`}</p>
                  </li>
                  <li>
                    <p><strong>{`EU`}</strong>
                      {` – an IP in a block used by
multiple European countries.`}</p>
                  </li>
                  <li>
                    <p><strong>{`AP`}</strong>
                      {` – an IP in a block used by
multiple Asia/Pacific region countries.`}</p>
                  </li>
                </ul>
                <p>
                  <p>{`The `}
                    <strong>{`US`}</strong>
                    {` country code is returned for IP addresses associated
with overseas US military bases.`}</p>
                </p>
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Country name`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The country name as defined by `}
                  <a href="https://www.geonames.org/">{`GeoNames`}</a>
                  {` associated with the IP address.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Continent code`}</p>
              </td>
              <td>
                <p>{`string (2)`}</p>
              </td>
              <td>
                <p>
                  <p>{`A two-character code for the continent associated with the IP
address. The possible codes are:`}</p>
                </p>
                <ul>
                  <li>
                    <p><strong>{`AF`}</strong>
                      {` – Africa`}</p>
                  </li>
                  <li>
                    <p><strong>{`AN`}</strong>
                      {` – Antarctica`}</p>
                  </li>
                  <li>
                    <p><strong>{`AS`}</strong>
                      {` – Asia`}</p>
                  </li>
                  <li>
                    <p><strong>{`EU`}</strong>
                      {` – Europe`}</p>
                  </li>
                  <li>
                    <p><strong>{`NA`}</strong>
                      {` – North America`}</p>
                  </li>
                  <li>
                    <p><strong>{`OC`}</strong>
                      {` – Oceania`}</p>
                  </li>
                  <li>
                    <p><strong>{`SA`}</strong>
                      {` – South America`}</p>
                  </li>
                </ul>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Latitude`}</p>
              </td>
              <td>
                <p>{`decimal`}</p>
              </td>
              <td>
                <p>{`The approximate latitude of the location associated with the network. This value is not precise and should not be used to identify a particular address or household`}</p>
              </td>
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Longitude`}</p>
              </td>
              <td>
                <p>{`decimal`}</p>
              </td>
              <td>
                <p>{`The approximate longitude of the location associated with the network. Latitude and Longitude are often near the center of population. These values are not precise and should not be used to identify a particular address or household.`}</p>
              </td>
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Time zone`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The time zone associated with the IP address. Time zone names are taken from
the `}
                  <a href="https://www.iana.org/time-zones/">{`IANA time zone database`}</a>
                  {`. See
the `}
                  <a href="/static/csv/codes/time_zone.csv">{`list of possible values`}</a>
                  {`.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`AS number`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The `}
                  <a href="https://en.wikipedia.org/wiki/Autonomous_system_(Internet)">{`autonomous system number`}</a>
                  {` associated with the IP address.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`User type`}</p>
              </td>
              <td>
                <p>{`enum`}</p>
              </td>
              <td>
                <p>
                  <p>{`The user type associated with the IP address. This will be one of the following
values.`}</p>
                </p>
                <ul>
                  <li>
                    <strong>
                      <p>{`business`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`cafe`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`cellular`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`college`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`contentDeliveryNetwork`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`government`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`hosting`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`library`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`military`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`residential`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`router`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`school`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`searchEngineSpider`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`traveler`}</p>
                    </strong>
                  </li>
                </ul>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Netspeed`}</p>
              </td>
              <td>
                <p>{`enum`}</p>
              </td>
              <td>
                <p>
                  <p>{`The network speed associated with the IP address. This can be one of the
following values:`}</p>
                </p>
                <ul>
                  <li>
                    <strong>
                      <p>{`Dialup`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`Cable/DSL`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`Corporate`}</p>
                    </strong>
                  </li>
                  <li>
                    <strong>
                      <p>{`Cellular`}</p>
                    </strong>
                  </li>
                </ul>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Domain`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The second level domain associated with the IP address. This will be`}</p>
                <p>{`something like "example.com" or "example.co.uk", not "foo.example.com".`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`ISP name`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The name of the ISP associated with the IP address.`}</p>
              </td>
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Organization name`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`The name of the organization associated with the IP address.`}</p>
              </td>
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`City confidence factor`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`A value from 0-100 representing our confidence that the city is correct.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Region confidence factor`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`A value from 0-100 representing our confidence that the region is correct.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Postal confidence factor`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`A value from 0-100 representing our confidence that the postal code is correct.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Country confidence factor`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`A value from 0-100 representing our confidence that the country is correct.`}</p>
              </td>
              <td />
              <td />
              <td />
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Error code`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>
                  <p>{`If there was an error or warning with this request, this field
contains an error code string.`}</p>
                </p>
                <p>
                  <p>{`The possible error codes are:`}</p>
                </p>
                <ul>
                  <li>
                    <p><strong>{`PERMISSION_REQUIRED`}</strong>
                      {` – This is returned if you do not have permission to use the service. Please contact `}
                      <a href="mailto:support@maxmind.com"><a {...{
                          "href": "mailto:support@maxmind.com"
                        }}>{`support@maxmind.com`}</a></a>
                      {` for more information.`}</p>
                  </li>
                  <li>
                    <p><strong>{`INVALID_LICENSE_KEY`}</strong>
                      {` – This error will be returned
when the license key you pass is not a valid license key or when your account has run out of queries.`}</p>
                  </li>
                  <li>
                    <p><strong>{`LICENSE_REQUIRED`}</strong>
                      {` – The Insight service returns this instead of INVALID_LICENSE_KEY.`}</p>
                  </li>
                  <li>
                    <p><strong>{`IP_NOT_FOUND`}</strong>
                      {` – This error will be returned if the
IP address it not valid, if it is not public, or if it is not in
our GeoIP database. It will also be returned if you do not pass an
IP address at all.`}</p>
                  </li>
                </ul>
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
              <td>
                <FaCheck mdxType="FaCheck" />
              </td>
            </tr>
          </tbody>
        </table></div>
      <div {...{
        "id": "toc-output-field-order",
        "parentName": "div"
      }}><h3 {...{
          "id": "output-field-order",
          "parentName": "div"
        }}>{`Output field order`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`Since all output is returned as a comma separated string, the order in which
fields are returned must be known in order to parse the result. If the request
is successful, the error field may be omitted entirely, since it always comes
last.`}</p>
        <table>
          <thead>
            <tr>
              <th>
                <p>{`Service`}</p>
              </th>
              <th>
                <p>{`Field Order`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>{`Country`}</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>{`Country code`}</p>
                  </li>
                  <li>
                    <p>{`Error`}</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`City`}</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>{`Country code`}</p>
                  </li>
                  <li>
                    <p>{`Region code`}</p>
                  </li>
                  <li>
                    <p>{`City name`}</p>
                  </li>
                  <li>
                    <p>{`Latitude`}</p>
                  </li>
                  <li>
                    <p>{`Longitude`}</p>
                  </li>
                  <li>
                    <p>{`Error`}</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`City/ISP/Org`}</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>{`Country code`}</p>
                  </li>
                  <li>
                    <p>{`Region code`}</p>
                  </li>
                  <li>
                    <p>{`City name`}</p>
                  </li>
                  <li>
                    <p>{`Postal code`}</p>
                  </li>
                  <li>
                    <p>{`Latitude`}</p>
                  </li>
                  <li>
                    <p>{`Longitude`}</p>
                  </li>
                  <li>
                    <p>{`Metro code`}</p>
                  </li>
                  <li>
                    <p>{`Area code`}</p>
                  </li>
                  <li>
                    <p>{`ISP name`}</p>
                  </li>
                  <li>
                    <p>{`Organization name`}</p>
                  </li>
                  <li>
                    <p>{`Error`}</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`Insights`}</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>{`Country code`}</p>
                  </li>
                  <li>
                    <p>{`Country name`}</p>
                  </li>
                  <li>
                    <p>{`Region code`}</p>
                  </li>
                  <li>
                    <p>{`Region name`}</p>
                  </li>
                  <li>
                    <p>{`City name`}</p>
                  </li>
                  <li>
                    <p>{`Latitude`}</p>
                  </li>
                  <li>
                    <p>{`Longitude`}</p>
                  </li>
                  <li>
                    <p>{`Metro code`}</p>
                  </li>
                  <li>
                    <p>{`Area code`}</p>
                  </li>
                  <li>
                    <p>{`Time zone`}</p>
                  </li>
                  <li>
                    <p>{`Continent code`}</p>
                  </li>
                  <li>
                    <p>{`Postal code`}</p>
                  </li>
                  <li>
                    <p>{`ISP name`}</p>
                  </li>
                  <li>
                    <p>{`Organization name`}</p>
                  </li>
                  <li>
                    <p>{`Domain`}</p>
                  </li>
                  <li>
                    <p>{`AS number`}</p>
                  </li>
                  <li>
                    <p>{`Netspeed`}</p>
                  </li>
                  <li>
                    <p>{`User type`}</p>
                  </li>
                  <li>
                    <p>{`Accuracy radius`}</p>
                  </li>
                  <li>
                    <p>{`Country confidence factor`}</p>
                  </li>
                  <li>
                    <p>{`City confidence factor`}</p>
                  </li>
                  <li>
                    <p>{`Region confidence factor`}</p>
                  </li>
                  <li>
                    <p>{`Postal confidence factor`}</p>
                  </li>
                  <li>
                    <p>{`Error`}</p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table></div>
      <div {...{
        "id": "toc-client-code-examples",
        "parentName": "div"
      }}><h3 {...{
          "id": "client-code-examples",
          "parentName": "div"
        }}>{`Client Code Examples`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The examples below are all for the Insights or City/ISP/Org web services. Client
code for other services will be very similar. The only differences are the URI
path and the fields which are returned.`}</p>
        <div {...{
          "id": "toc-perl",
          "parentName": "div"
        }}><h4 {...{
            "id": "perl",
            "parentName": "div"
          }}>{`Perl`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the Insights web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-perl",
              "parentName": "pre"
            }}>{`#!/usr/bin/env perl

use strict;
use warnings;

use Encode qw( decode );
use Getopt::Long;
use LWP::UserAgent;
use Text::CSV_XS;
use URI;
use URI::QueryParam;

my @fields = qw(
    country_code
    country_name
    region_code
    region_name
    city_name
    latitude
    longitude
    metro_code
    area_code
    time_zone
    continent_code
    postal_code
    isp_name
    organization_name
    domain
    as_number
    netspeed
    user_type
    accuracy_radius
    country_confidence
    city_confidence
    region_confidence
    postal_confidence
    error
);

my $license_key = 'YOUR_LICENSE_KEY';
my $ip_address  = '24.24.24.24';

GetOptions(
    'license:s' => \\$license_key,
    'ip:s'      => \\$ip_address,
);

my $uri = URI->new('https://geoip.maxmind.com/v1.0/insights');
$uri->query_param( l => $license_key );
$uri->query_param( i => $ip_address );

my $ua = LWP::UserAgent->new( timeout => 5 );
my $response = $ua->get($uri);

die 'Request failed with status ' . $response->code()
    unless $response->is_success();

my $csv = Text::CSV_XS->new( { binary => 1 } );
$csv->parse( decode( 'ISO-8859-1', $response->content() ) );

my %insights;
@insights{@fields} = $csv->fields();

binmode STDOUT, ':encoding(UTF-8)';

if ( defined $insights{error} && length $insights{error} ) {
    die "MaxMind returned an error code for the request: $insights{error}\\n";
}
else {
    print "\\nMaxMind Insights data for $ip_address\\n\\n";
    for my $field (@fields) {
        print sprintf( "  %-20s  %s\\n", $field, $insights{$field} );
    }
    print "\\n";
}
`}</code></pre></div>
        <div {...{
          "id": "toc-php",
          "parentName": "div"
        }}><h4 {...{
            "id": "php",
            "parentName": "div"
          }}>{`PHP`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the Insights web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-php",
              "parentName": "pre"
            }}>{`#!/usr/bin/env php

<?php

$params = getopt('l:i:');

if (!isset($params['l'])) $params['l'] = 'YOUR_LICENSE_KEY';
if (!isset($params['i'])) $params['i'] = '24.24.24.24';

$query = 'https://geoip.maxmind.com/v1.0/insights?' . http_build_query($params);

$insights_keys =
  array(
    'country_code',
    'country_name',
    'region_code',
    'region_name',
    'city_name',
    'latitude',
    'longitude',
    'metro_code',
    'area_code',
    'time_zone',
    'continent_code',
    'postal_code',
    'isp_name',
    'organization_name',
    'domain',
    'as_number',
    'netspeed',
    'user_type',
    'accuracy_radius',
    'country_confidence',
    'city_confidence',
    'region_confidence',
    'postal_confidence',
    'error'
    );

$curl = curl_init();
curl_setopt_array(
    $curl,
    array(
        CURLOPT_URL => $query,
        CURLOPT_USERAGENT => 'MaxMind PHP Sample',
        CURLOPT_RETURNTRANSFER => true
    )
);

$resp = curl_exec($curl);

if (curl_errno($curl)) {
    throw new Exception(
        'GeoIP request failed with a curl_errno of '
        . curl_errno($curl)
    );
}

$insights_values = str_getcsv($resp);
$insights_values = array_pad($insights_values, sizeof($insights_keys), '');
$insights = array_combine($insights_keys, $insights_values);

print_r($insights);
`}</code></pre></div>
        <div {...{
          "id": "toc-python-2",
          "parentName": "div"
        }}><h4 {...{
            "id": "python-2",
            "parentName": "div"
          }}>{`Python 2`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the Insights web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-python",
              "parentName": "pre"
            }}>{`#!/usr/bin/env python

import argparse
import csv
import requests
import sys

fields = ['country_code',
          'country_name',
          'region_code',
          'region_name',
          'city_name',
          'latitude',
          'longitude',
          'metro_code',
          'area_code',
          'time_zone',
          'continent_code',
          'postal_code',
          'isp_name',
          'organization_name',
          'domain',
          'as_number',
          'netspeed',
          'user_type',
          'accuracy_radius',
          'country_confidence',
          'city_confidence',
          'region_confidence',
          'postal_confidence',
          'error']

parser = argparse.ArgumentParser(description='MaxMind Insights web service client')
parser.add_argument('--license', default='YOUR_LICENSE_KEY')
parser.add_argument('--ip', default='24.24.24.24')

args = parser.parse_args()

payload = {'l': args.license, 'i': args.ip};
response = requests.get('https://geoip.maxmind.com/v1.0/insights', params=payload)

if response.status_code != requests.codes.ok:
    sys.stderr.write("Request failed with status %s\\n" % response.status_code)
    sys.exit(1)

reader = csv.reader([response.content])

insights = dict(zip(fields, [unicode(s, 'latin_1') for s in reader.next()]))
if len(insights['error']):
    sys.stderr.write("MaxMind returned an error code for the request: %s\\n" % insights['error'])
    sys.exit(1)
else:
    print "\\nMaxMind Insights data for %s\\n\\n" % args.ip
    for (key, val) in insights.items():
        print "  %-20s  %s" % (key, val)
    print "\\n"
`}</code></pre></div>
        <div {...{
          "id": "toc-python-3",
          "parentName": "div"
        }}><h4 {...{
            "id": "python-3",
            "parentName": "div"
          }}>{`Python 3`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the Insights web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-python",
              "parentName": "pre"
            }}>{`#!/usr/bin/env python

import argparse
import csv
import requests
import sys

fields = ['country_code',
          'country_name',
          'region_code',
          'region_name',
          'city_name',
          'latitude',
          'longitude',
          'metro_code',
          'area_code',
          'time_zone',
          'continent_code',
          'postal_code',
          'isp_name',
          'organization_name',
          'domain',
          'as_number',
          'netspeed',
          'user_type',
          'accuracy_radius',
          'country_confidence',
          'city_confidence',
          'region_confidence',
          'postal_confidence',
          'error']

parser = argparse.ArgumentParser(description='MaxMind Insights web service client')
parser.add_argument('--license', default='YOUR_LICENSE_KEY')
parser.add_argument('--ip', default='24.24.24.24')

args = parser.parse_args()

payload = {'l': args.license, 'i': args.ip};
response = requests.get('https://geoip.maxmind.com/v1.0/insights', params=payload)

if response.status_code != requests.codes.ok:
    sys.stderr.write("Request failed with status %s\\n" % response.status_code)
    sys.exit(1)

reader = csv.reader([response.text])

insights = dict(zip(fields, next(reader)))
if len(insights['error']):
    sys.stderr.write("MaxMind returned an error code for the request: %s\\n" % insights['error'])
    sys.exit(1)
else:
    print("\\nMaxMind Insights data for %s\\n\\n" % args.ip)
    for (key, val) in insights.items():
        print("  %-20s  %s" % (key, val))
    print("\\n")
`}</code></pre></div>
        <div {...{
          "id": "toc-ruby-19",
          "parentName": "div"
        }}><h4 {...{
            "id": "ruby-19",
            "parentName": "div"
          }}>{`Ruby 1.9`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the Insights web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-ruby",
              "parentName": "pre"
            }}>{`#!/usr/bin/env ruby

require 'csv'
require 'net/http'
require 'open-uri'
require 'optparse'
require 'uri'

fields = [:country_code,
          :country_name,
          :region_code,
          :region_name,
          :city_name,
          :latitude,
          :longitude,
          :metro_code,
          :area_code,
          :time_zone,
          :continent_code,
          :postal_code,
          :isp_name,
          :organization_name,
          :domain,
          :as_number,
          :netspeed,
          :user_type,
          :accuracy_radius,
          :country_confidence,
          :city_confidence,
          :region_confidence,
          :postal_confidence,
          :error]

options = { :license => "YOUR_LICENSE_KEY", :ip => "24.24.24.24" }
OptionParser.new { |opts|
  opts.banner = "Usage: insights-geoip-ws.rb [options]"

  opts.on("-l", "--license LICENSE", "MaxMind license key") do |l|
    options[:license] = l
  end

  opts.on("-i", "--ip IPADDRESS", "IP address to look up") do |i|
    options[:ip] = i
  end
}.parse!

uri = URI::HTTP.build(:scheme => 'https',
                      :host   => 'geoip.maxmind.com',
                      :path   => '/v1.0/insights',
                      :query  => URI.encode_www_form(:l => options[:license],
                                                     :i => options[:ip]))

response = Net::HTTP.get_response(uri)

unless response.is_a?(Net::HTTPSuccess)
  abort "Request failed with status #{response.code}"
end

insights = Hash[fields.zip(response.body.encode('utf-8', 'iso-8859-1').parse_csv)]

if insights[:error]
  abort "MaxMind returned an error code for the request: #{insights[:error]}"
else
  puts
  puts "MaxMind Insights data for #{options[:ip]}";
  puts
  insights.each { |key, val| printf "  %-20s  %s\\n", key, val }
  puts
end
`}</code></pre></div>
        <div {...{
          "id": "toc-java",
          "parentName": "div"
        }}><h4 {...{
            "id": "java",
            "parentName": "div"
          }}>{`Java`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the Insights web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-java",
              "parentName": "pre"
            }}>{`import java.net.MalformedURLException;
import java.net.URL;
import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.io.IOException;
import java.util.ArrayList;
import java.util.List;
import java.util.regex.Pattern;
import java.util.regex.Matcher;

public class InsightsReader {
    public static void main(String[] args) throws Exception {
        String license_key = "YOUR_LICENSE_KEY";
        String ip_address = "24.24.24.24";

        String url_str = "https://geoip.maxmind.com/v1.0/insights?l=" + license_key + "&i=" + ip_address;

        URL url = new URL(url_str);
        BufferedReader in = new BufferedReader(new InputStreamReader(url.openStream()));
        String inLine;

        while ((inLine = in.readLine()) != null) {
            // Alternatively use a CSV parser here.
            Pattern p = Pattern.compile("\\"([^\\"]*)\\"|(?<=,|^)([^,]*)(?:,|$)");
            Matcher m = p.matcher(inLine);

            List<String> fields = new ArrayList<String>();
            String f;
            while (m.find()) {
                f = m.group(1);
                if (f!=null) {
                    fields.add(f);
                }
                else {
                    fields.add(m.group(2));
                }
            }

            String countrycode = fields.get(0);
            String countryname = fields.get(1);
            String regioncode = fields.get(2);
            String regionname = fields.get(3);
            String city = fields.get(4);
            String lat = fields.get(5);
            String lon = fields.get(6);
            String metrocode = fields.get(7);
            String areacode = fields.get(8);
            String timezone = fields.get(9);
            String continent = fields.get(10);
            String postalcode = fields.get(11);
            String isp = fields.get(12);
            String org = fields.get(13);
            String domain = fields.get(14);
            String asnum = fields.get(15);
            String netspeed = fields.get(16);
            String usertype = fields.get(17);
            String accuracyradius = fields.get(18);
            String countryconf = fields.get(19);
            String cityconf = fields.get(20);
            String regionconf = fields.get(21);
            String postalconf = fields.get(22);
            String error = fields.get(23);
        }

        in.close();
    }
}
`}</code></pre></div>
        <div {...{
          "id": "toc-c",
          "parentName": "div"
        }}><h4 {...{
            "id": "c",
            "parentName": "div"
          }}>{`C#`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the Insights web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "className": "language-csharp",
              "parentName": "pre"
            }}>{`// Contributed by Gokhan Saltik
private string GetMaxMindInsightsData(string IP) {
  System.Uri objUrl = new System.Uri("https://geoip.maxmind.com/v1.0/insights?l=YOUR_LICENSE_KEY&i=" + IP);
  System.Net.WebRequest objWebReq;
  System.Net.WebResponse objResp;
  System.IO.StreamReader sReader;
  string strReturn = string.Empty;

  try
    {
      objWebReq = System.Net.WebRequest.Create(objUrl);
      objResp = objWebReq.GetResponse();

      sReader = new System.IO.StreamReader(objResp.GetResponseStream());
      strReturn = sReader.ReadToEnd();

      sReader.Close();
      objResp.Close();
    }
  catch (Exception ex)
    {
    }
  finally
    {
      objWebReq = null;
    }

  return strReturn;
}

`}</code></pre></div>
        <div {...{
          "id": "toc-vbnet",
          "parentName": "div"
        }}><h4 {...{
            "id": "vbnet",
            "parentName": "div"
          }}>{`VB.Net`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the City/ISP/Org web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "parentName": "pre"
            }}>{`' Contributed by Rubens A. Lucca
Private Function ReturnData(ByVal IP As String) As String
        Dim objUrl As New System.Uri("https://geoip.maxmind.com/v.10/city-isp-org?l=YOUR_LICENSE_KEY&i=" & IP)
        Dim objWebReq As System.Net.WebRequest
        Dim objResp As System.Net.WebResponse
        Dim sReader As System.IO.StreamReader
        Dim strReturn As String

        'Try to connect to the server and retrieve data.
        Try
            objWebReq = System.Net.WebRequest.Create(objUrl)
            objResp = objWebReq.GetResponse

            'Get the data and store in a return string.
            sReader = New System.IO.StreamReader(objResp.GetResponseStream)
            strReturn = sReader.ReadToEnd

            'Close the objects.
            sReader.Close()
            objResp.Close()
        Catch ex As Exception
        Finally
            objWebReq = Nothing
        End Try

        Return strReturn

    End Function
`}</code></pre></div>
        <div {...{
          "id": "toc-cold-fusion",
          "parentName": "div"
        }}><h4 {...{
            "id": "cold-fusion",
            "parentName": "div"
          }}>{`Cold Fusion`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the City/ISP/Org web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "parentName": "pre"
            }}>{`<!--- contributed by reinhard jung --->
<cfhttp method="get" url="https://geoip.maxmind.com/v1.0/city-isp-org?l=LicenceKey&i=#CGI.REMOTE_ADDR#"></cfhttp>
<cfset resultMaxMind = cfhttp.FileContent>

<!--- create Array --->
<cfset qMaxMindByID = structNew()/>
<cfset qMaxMindByName = structNew()/>
<cfset thisField = "country,region,city,postal,latitude,longitude,metroCode,area,ISP,organization"/>
<cfset thisPos = 1/>
<cfset thisValue = ""/>
<cfset stringField = "false"/>
<cfloop from="1" to="#Len(resultMaxMind)#" index="mmField">
        <cfif mid(resultMaxMind,mmField,1) IS ',' AND NOT stringField>
                <cfset qMaxMindByID[thisPos] = thisValue>
                <cfset qMaxMindByName['#ListgetAt(thisField,thisPos)#'] = thisValue>
                <cfset thisPos = thisPos +1/>
                <cfset thisValue = ""/>
        <cfelse>
                <cfif mid(resultMaxMind,mmField,1) IS '"'>
                        <cfset stringField = iif(stringField,"false","true")/>
                <cfelse>
                        <cfset thisValue = thisValue &mid(resultMaxMind,mmField,1)/>
                </cfif>
        </cfif>
        <cfif Len(resultMaxMind) EQ mmField>
                <cfset qMaxMindByID[thisPos] = thisValue/>
                <cfset qMaxMindByName['#ListgetAt(thisField,thisPos)#'] = thisValue>
        </cfif>
</cfloop>

<!--- access Array --->
<br /><cfoutput>#qMaxMindByID[3]#</cfoutput>
<br /><cfoutput>#qMaxMindByName['city']#</cfoutput>

<!--- dump Array for overview --->
<cfdump var="#qMaxMindByID#" label="qMaxMindByID"><br />
<cfdump var="#qMaxMindByName#" label="qMaxMindByName"><br />
`}</code></pre></div>
        <div {...{
          "id": "toc-asp",
          "parentName": "div"
        }}><h4 {...{
            "id": "asp",
            "parentName": "div"
          }}>{`ASP`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the City/ISP/Org web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "parentName": "pre"
            }}>{`Dim objHttp, strQuery
strQuery = "https://geoip.maxmind.com/v1.0/city-isp-org?l=" & license_key & "&i=" & ipaddress
set objHttp = Server.CreateObject("Msxml2.ServerXMLHTTP")
objHttp.open "GET", strQuery, false
objHttp.send
Response.Write objHttp.ResponseText
Set objHttp = Nothing
`}</code></pre></div>
        <div {...{
          "id": "toc-vbscript",
          "parentName": "div"
        }}><h4 {...{
            "id": "vbscript",
            "parentName": "div"
          }}>{`VBScript`}</h4>
          <p {...{
            "parentName": "div"
          }}>{`This is an example for the City/ISP/Org web service.`}</p>
          <pre {...{
            "parentName": "div"
          }}><code {...{
              "parentName": "pre"
            }}>{`Set request = Server.CreateObject("AspHTTP.Conn")
request.Url = "https://geoip.maxmind.com/v1.0/city-isp-org?l=" & license_key & "&i=" & ip_address
request.RequestMethod = "GET"
string = request.GetURL
data = Split(string, ",")
country = arr(0)
region = arr(1)
city = arr(2)
postal = arr(3)
latitude = arr(4)
longitude = arr(5)
metro_code = arr(6)
area_code = arr(7)
isp = arr(8)
organization = arr(9)
error = arr(10)
`}</code></pre></div></div></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      